import React from "react";
import { useOverrides, Override, Formspree } from "@quarkly/components";
import { Text, Input, Box, Button, Em, Strong, LinkBox, Image, Icon, Span, Section } from "@quarkly/widgets";
import { MdEmail, MdLocationOn } from "react-icons/md";
const defaultProps = {
	"background": "--color-lightD1",
	"padding": "60px 0 40px 0",
	"md-padding": "40px 0 40px 0",
	"id": "footer"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"md-flex-direction": "column"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"md-max-width": "100%",
			"sm-padding": "24px 12px 36px 12px",
			"width": "60%",
			"border-width": "1px",
			"border-color": "#e2e3e6",
			"border-radius": "5px",
			"border-style": "solid",
			"padding": "20px 48px 20px 48px",
			"md-width": "100%"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "normal 400 30px/1.2 --fontFamily-googlePoppins",
			"sm-padding": "0px 8px 0px 8px",
			"sm-margin": "0 0 8px 0",
			"color": "--darkL2",
			"sm-font": "normal 400 20px/1.2 --fontFamily-googleInter",
			"children": "Get in Touch"
		}
	},
	"formspree": {
		"kind": "Formspree",
		"props": {
			"endpoint": "xbjerwee",
			"errorMessage": "Something went wrong. Please try again.",
			"completeText": "Sent! We'll be in contact shortly.",
			"color": "#ffffff",
			"font": "500 20px/24px --fontFamily-googleInter"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"gap": "16px",
			"display": "flex",
			"flex-direction": "row",
			"flex-wrap": "wrap"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"width": "100%",
			"display": "flex",
			"flex-direction": "column",
			"padding": "8px 8px 8px 0px"
		}
	},
	"input": {
		"kind": "Input",
		"props": {
			"width": "100%",
			"name": "name",
			"type": "text",
			"placeholder": "Name",
			"required": true
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"padding": "8px 8px 8px 0px",
			"width": "100%",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"input1": {
		"kind": "Input",
		"props": {
			"width": "100%",
			"type": "email",
			"name": "email",
			"placeholder": "Email",
			"required": true
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"padding": "8px 8px 8px 0px",
			"width": "100%",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"input2": {
		"kind": "Input",
		"props": {
			"as": "textarea",
			"rows": "4",
			"width": "100%",
			"name": "message",
			"placeholder": "Message",
			"required": true
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"padding": "8px 8px 8px 8px",
			"width": "100%",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-end"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"background": "rgba(254, 170, 204, 0)",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "#cbd5e1",
			"color": "--indigo",
			"href": "mailto:aidvertize@gmail.com?Customer Message",
			"type": "submit",
			"hover-color": "--indigo",
			"border-radius": "5px",
			"hover-background": "rgba(131, 54, 255, 0.07)",
			"focus-box-shadow": "0 0 0 0px --color-indigo",
			"focus-border-color": "--color-indigo",
			"hover-border-color": "--color-indigo",
			"transition": "all .3s ease 0s"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "40%",
			"display": "flex",
			"flex-direction": "row",
			"md-width": "100%"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"empty-border-color": "LightGray",
			"flex-direction": "column",
			"display": "flex",
			"padding": "16px 16px 0px 16px",
			"md-width": "100%",
			"empty-min-width": "64px",
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"margin": "0px 0px 0px 30px",
			"md-margin": "0px 0px 0px 0px",
			"sm-padding": "16px 0px 16px 0px",
			"width": "100%",
			"lg-padding": "0px 16px 0px 16px"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "50px",
			"md-display": "flex",
			"sm-flex-direction": "column",
			"md-flex-direction": "column",
			"md-margin": "30px 0px 20px 0px",
			"lg-display": "flex",
			"lg-flex-direction": "column",
			"lg-justify-content": "flex-start",
			"lg-margin": "0px 0px 30px 0px",
			"margin": "0px 0px 40px 0px",
			"sm-margin": "30px 0px 30px 0px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h4",
			"margin": "6px 0",
			"font": "--base",
			"sm-width": "100%",
			"color": "--darkL2",
			"children": <Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				Explore Aid-
				<Em>
					vertise
				</Em>
			</Strong>
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "40px",
			"md-display": "flex",
			"md-min-height": "50px",
			"lg-min-height": "50px",
			"sm-flex-direction": "row",
			"display": "flex",
			"flex-direction": "row",
			"lg-flex-direction": "column",
			"md-flex-direction": "row"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"text-align": "left",
			"align-items": "flex-start",
			"href": "/index#home",
			"md-width": "50%",
			"md-margin": "0px 0px 0px 0px",
			"sm-margin": "7px 0px 7px 0px",
			"width": "50%",
			"lg-width": "100%",
			"lg-margin": "0px 0px 10px 0px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "normal 400 14px/1.6 --fontFamily-googleInter",
			"sm-width": "100%",
			"color": "rgba(55, 65, 81, 0.7)",
			"margin": "0px 0px 0px 0px",
			"sm-color": "rgba(55, 65, 81, 0.7)",
			"border-color": "rgba(55, 65, 81, 0.7)",
			"hover-color": "#374151",
			"transition": "all .3s ease-in-out 0s",
			"children": "Home"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"text-align": "left",
			"align-items": "flex-start",
			"href": "/index#download",
			"md-width": "50%",
			"md-margin": "0px 0px 0px 0px",
			"sm-margin": "7px 0px 7px 0px",
			"transition": "--transformInOut",
			"width": "50%",
			"lg-width": "100%",
			"lg-margin": "0px 0px 10px 0px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "normal 400 14px/1.6 --fontFamily-googleInter",
			"sm-width": "100%",
			"color": "rgba(55, 65, 81, 0.7)",
			"margin": "0px 0px 0px 0px",
			"sm-color": "rgba(55, 65, 81, 0.7)",
			"hover-color": "#374151",
			"transition": "all .3s ease-in-out 0s",
			"children": "Download"
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "40px",
			"md-display": "flex",
			"md-min-height": "50px",
			"lg-min-height": "50px",
			"sm-flex-direction": "row",
			"display": "flex",
			"flex-direction": "row",
			"lg-flex-direction": "column",
			"md-flex-direction": "row"
		}
	},
	"linkBox2": {
		"kind": "LinkBox",
		"props": {
			"text-align": "left",
			"align-items": "flex-start",
			"href": "/turning-off-adblock",
			"md-width": "50%",
			"md-margin": "0px 0px 0px 0px",
			"sm-margin": "7px 0px 7px 0px",
			"width": "50%",
			"lg-width": "100%",
			"lg-margin": "0px 0px 10px 0px"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "normal 400 14px/1.6 --fontFamily-googleInter",
			"sm-width": "100%",
			"color": "rgba(55, 65, 81, 0.7)",
			"margin": "0px 0px 0px 0px",
			"sm-color": "rgba(55, 65, 81, 0.7)",
			"border-color": "rgba(55, 65, 81, 0.7)",
			"hover-color": "#374151",
			"transition": "all .3s ease-in-out 0s",
			"children": "Turning off Adblock"
		}
	},
	"linkBox3": {
		"kind": "LinkBox",
		"props": {
			"text-align": "left",
			"align-items": "flex-start",
			"href": "/feedback",
			"md-width": "50%",
			"md-margin": "0px 0px 0px 0px",
			"sm-margin": "7px 0px 7px 0px",
			"transition": "--transformInOut",
			"width": "50%",
			"lg-width": "100%",
			"lg-margin": "0px 0px 10px 0px"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "normal 400 14px/1.6 --fontFamily-googleInter",
			"sm-width": "100%",
			"color": "rgba(55, 65, 81, 0.7)",
			"margin": "0px 0px 0px 0px",
			"sm-color": "rgba(55, 65, 81, 0.7)",
			"hover-color": "#374151",
			"transition": "all .3s ease-in-out 0s",
			"children": "Share Feedback"
		}
	},
	"box12": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "40px",
			"md-display": "flex",
			"md-min-height": "50px",
			"lg-min-height": "50px",
			"sm-flex-direction": "row",
			"display": "flex",
			"flex-direction": "row",
			"lg-flex-direction": "column",
			"md-flex-direction": "row"
		}
	},
	"linkBox4": {
		"kind": "LinkBox",
		"props": {
			"text-align": "left",
			"align-items": "flex-start",
			"href": "https://docs.google.com/document/d/1ACP719Mx-jX_lFS3Uu6NA2EgcFmtzOJgbiWfjQrpiUQ/edit?usp=sharing",
			"md-width": "50%",
			"md-margin": "0px 0px 0px 0px",
			"sm-margin": "7px 0px 7px 0px",
			"width": "50%",
			"lg-width": "100%",
			"lg-margin": "0px 0px 10px 0px",
			"target": "_blank"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "normal 400 14px/1.6 --fontFamily-googleInter",
			"sm-width": "100%",
			"color": "rgba(55, 65, 81, 0.7)",
			"margin": "0px 0px 0px 0px",
			"sm-color": "rgba(55, 65, 81, 0.7)",
			"border-color": "rgba(55, 65, 81, 0.7)",
			"hover-color": "#374151",
			"transition": "all .3s ease-in-out 0s",
			"children": "Privacy Policy"
		}
	},
	"linkBox5": {
		"kind": "LinkBox",
		"props": {
			"text-align": "left",
			"align-items": "flex-start",
			"href": "https://docs.google.com/document/d/1JofT-rkrrPI8JosmsvttYE2Ymv549wEHgUX65Fi2Fh8/edit",
			"md-width": "50%",
			"md-margin": "0px 0px 0px 0px",
			"sm-margin": "7px 0px 7px 0px",
			"transition": "--transformInOut",
			"width": "50%",
			"lg-width": "100%",
			"lg-margin": "0px 0px 10px 0px",
			"target": "_blank"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "normal 400 14px/1.6 --fontFamily-googleInter",
			"sm-width": "100%",
			"color": "rgba(55, 65, 81, 0.7)",
			"margin": "0px 0px 0px 0px",
			"sm-color": "rgba(55, 65, 81, 0.7)",
			"hover-color": "#374151",
			"transition": "all .3s ease-in-out 0s",
			"children": "Terms of Service"
		}
	},
	"box13": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-margin": "20px 0px 0px 0px",
			"sm-align-items": "center",
			"sm-justify-content": "center"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"height": "auto",
			"src": "https://uploads.quarkly.io/63a25993f63de40026725928/images/Group%2022.png?v=2024-01-02T23:15:06.973Z",
			"max-width": "150px",
			"margin": "0px 0px 0px 5px",
			"sm-width": "100%"
		}
	},
	"box14": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"md-display": "flex",
			"sm-flex-direction": "column"
		}
	},
	"box15": {
		"kind": "Box",
		"props": {
			"padding": "0 0 0 64px",
			"sm-padding": "40px 0 0 0",
			"margin": "20px 0 0 0",
			"max-width": "360px",
			"position": "relative",
			"sm-display": "flex",
			"sm-width": "100%",
			"sm-max-width": "100%",
			"sm-margin": "20px 0 0 0",
			"sm-flex-direction": "column"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"position": "absolute",
			"size": "30px",
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdEmail,
			"color": "--indigo",
			"width": "40px",
			"height": "40px",
			"sm-width": "30px"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"as": "h4",
			"margin": "6px 0",
			"font": "normal 600 14px/1.5 --fontFamily-googleInter",
			"sm-width": "100%",
			"color": "--dark",
			"border-color": "--color-dark",
			"children": <Span
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				Contact Us
			</Span>
		}
	},
	"linkBox6": {
		"kind": "LinkBox",
		"props": {
			"display": "block",
			"href": "mailto:aidvertise.team@gmail.com?Contact Aid-vertise"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "normal 400 14px/1.6 --fontFamily-googleInter",
			"sm-width": "100%",
			"color": "--blue",
			"children": "aidvertise.team@gmail.com"
		}
	},
	"box16": {
		"kind": "Box",
		"props": {
			"padding": "0 0 0 64px",
			"sm-padding": "40px 0 0 0",
			"margin": "20px 0 0 0",
			"max-width": "360px",
			"position": "relative",
			"sm-display": "flex",
			"sm-flex-direction": "column",
			"sm-width": "100%",
			"sm-max-width": "100%",
			"md-margin": "32px 0 0 30px",
			"sm-margin": "30px 0 0 0px"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"position": "absolute",
			"size": "30px",
			"top": "0",
			"left": "0",
			"category": "md",
			"icon": MdLocationOn,
			"color": "--indigo",
			"width": "40px",
			"height": "40px",
			"sm-width": "30px"
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			"as": "h4",
			"margin": "6px 0",
			"font": "normal 600 14px/1.5 \"Inter\", sans-serif",
			"sm-width": "100%",
			"color": "--darkL2",
			"children": <Span
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				Headquarters
			</Span>
		}
	},
	"text11": {
		"kind": "Text",
		"props": {
			"as": "p",
			"margin": "6px 0",
			"font": "normal 400 14px/1.6 --fontFamily-googleInter",
			"sm-width": "100%",
			"color": "--blue",
			"children": "Toronto, Canada"
		}
	},
	"text12": {
		"kind": "Text",
		"props": {
			"color": "--darkL2",
			"font": "700 13px/24px --fontFamily-googleInter",
			"margin": "20px 0px 0px 0px",
			"border-color": "--color-darkL2",
			"children": <Span font-weight="normal">
				© Aid-vertise. All Rights Reserved.
			</Span>
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="column" md-flex-wrap="wrap" md-flex-direction="column" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Formspree {...override("formspree")}>
					<Box {...override("box2")}>
						<Box {...override("box3")}>
							<Input {...override("input")} />
						</Box>
						<Box {...override("box4")}>
							<Input {...override("input1")} />
						</Box>
						<Box {...override("box5")}>
							<Input {...override("input2")} />
						</Box>
						<Box {...override("box6")}>
							<Button {...override("button")}>
								Send
							</Button>
						</Box>
					</Box>
				</Formspree>
			</Box>
			<Box {...override("box7")}>
				<Box {...override("box8")}>
					<Box {...override("box9")}>
						<Text {...override("text1")} />
						<Box {...override("box10")}>
							<LinkBox {...override("linkBox")}>
								<Text {...override("text2")} />
							</LinkBox>
							<LinkBox {...override("linkBox1")}>
								<Text {...override("text3")} />
							</LinkBox>
						</Box>
						<Box {...override("box11")}>
							<LinkBox {...override("linkBox2")}>
								<Text {...override("text4")} />
							</LinkBox>
							<LinkBox {...override("linkBox3")}>
								<Text {...override("text5")} />
							</LinkBox>
						</Box>
						<Box {...override("box12")}>
							<LinkBox {...override("linkBox4")}>
								<Text {...override("text6")} />
							</LinkBox>
							<LinkBox {...override("linkBox5")}>
								<Text {...override("text7")} />
							</LinkBox>
						</Box>
					</Box>
					<Box {...override("box13")}>
						<Image {...override("image")} />
					</Box>
					<Box {...override("box14")}>
						<Box {...override("box15")}>
							<Icon {...override("icon")} />
							<Text {...override("text8")} />
							<LinkBox {...override("linkBox6")}>
								<Text {...override("text9")} />
							</LinkBox>
						</Box>
						<Box {...override("box16")}>
							<Icon {...override("icon1")} />
							<Text {...override("text10")} />
							<Text {...override("text11")} />
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
		<Text {...override("text12")} />
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;