import React, { useRef, useLayoutEffect } from "react";
import atomize from "@quarkly/atomize"; // Put your HTML here:

const customHtml = `
<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdD7xriKzRKydqippvMGLxgU8FHC0Du7e9zTq_QavPHVjSecw/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f8fafc;
}

iframe {
    display: block;
    width: 100%;
    border: none;
    height: 100vh; /* Set initial height to full viewport height */
    min-height: 1200px;
    overflow: hidden; /* Hide scrollbars */
  padding-top: 40px;
  background-color: #f8fafc;
}

@media screen and (max-width: 461px) {
iframe {
    min-height: 1550px;
    }
}
</style>
`;

const EmbedHTML = ({
	children,
	...props
}) => {
	const ref = useRef(null);
	useLayoutEffect(() => {
		ref.current.innerHTML = customHtml;
	}, []);
	return <div {...props} ref={ref} />;
};

export default atomize(EmbedHTML)({
	name: "EmbedHTML",
	normalize: true,
	mixins: true
});