import React, { useRef, useLayoutEffect } from "react";
import atomize from "@quarkly/atomize"; // Put your HTML here:

const customHtml = `
<div style = "position: fixed; z-index: 16; padding-top: 5px;" id="navbar">
<div style = "margin: 0 auto; max-width:1280px; width: 90%">
  <div class = "test" style = "width:100%; float: left;">
    <a href="#home" style = "float:left; margin-right: 40px" class="active"> <image id = "logo" src="https://uploads.quarkly.io/63a25993f63de40026725928/images/Group%2022.png?v=2024-01-02T23:15:06.973Z"/></a> 
    <div class = "centernavbar">
      <ul id="mainNav">
        <li><a href="#purpose" class = "centernavbarobjects" id = "cno1" style = "padding: 20px 0 21px 0; font-size: 15px"> Purpose </a> </li>
        <li><a href="#steps" class = "centernavbarobjects" id = "cno2" style = "padding: 20px 0 21px 0; font-size: 15px"> Steps </a> </li>
        <li><a href="#features" class = "centernavbarobjects" id = "cno3" style = "padding: 20px 0 21px 0; font-size: 15px"> Features </a> </li>
        <li><a href="#partners" class = "centernavbarobjects" id = "cno4" style = "padding: 20px 0 21px 0; font-size: 15px"> Charities </a> </li>
        <li><a href="#download" class = "centernavbarobjects" id = "cno5" style = "padding: 20px 0 21px 0; font-size: 15px"> Download </a> </li>
      </ul>
    <div style = "float:right; width:100%">
      <a href="https://chromewebstore.google.com/detail/aid-vertise/clnahiecfdigjlgmealkpajafoedcobp" id = "trynav" class = "contactnav">Try Aid-vertise Now </a>
      <a href="#footer" id = "nFTnav" class = "NFTnav">Contact Us</a>
    </div>
    </div>
      <a style = "padding: 20px 10px 16px 10px; float:right" class="Hamburgermenu" id="hamburgermenu" onclick="openRightMenu()"> <img src="https://uploads.quarkly.io/628b9abe49c9db002345c3b6/images/images-removebg-preview.png?v=2022-06-04T09:26:51.056Z" display="flex" height="25px" /></a>
    </div>
  </div>
</div>

<div style="font-family: 'Inter', sans-serif; right:0; color: black;" id="rightMenu">
  <button onclick="closeRightMenu()" class="closeout" style = "text-align:right;font-size:30px"> &times</button>
  <a href="#home" onclick="closeRightMenu()" class="bar-item">Home</a>
  <a href="#purpose" onclick="closeRightMenu()" class="bar-item">Purpose</a>
  <a href="#steps" onclick="closeRightMenu()" class="bar-item">Steps</a>
  <a href="#features" onclick="closeRightMenu()" class="bar-item">Features</a>
  <a href="#partners" onclick="closeRightMenu()" class="bar-item">Charities</a>
  <a href="#download" onclick="closeRightMenu()" class="bar-item">Download</a>
  <a href="#home" class="activeside"> <image id="logoside" src="https://uploads.quarkly.io/63a25993f63de40026725928/images/Group%2021%20%282%29.png?v=2023-01-28T04:38:19.862Z"/> </a>
</div>
`;

const EmbedHTML = ({
	children,
	...props
}) => {
	const ref = useRef(null);
	useLayoutEffect(() => {
		ref.current.innerHTML = customHtml;
	}, []);
	return <div {...props} ref={ref} />;
};

export default atomize(EmbedHTML)({
	name: "EmbedHTML",
	normalize: true,
	mixins: true
});